import React, { useState, useCallback } from "react"
import {
  Divider,
  Grid,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import GiftForm from "./gifts/GiftForm"
import withForm from "@src/containers/form/withForm"

// const useStyles = makeStyles(theme => ({}))

const ContestForm = props => {
  // const classes = useStyles()

  const {
    // Events
    handleRemove,
    handleSubmit,
    handleCancel,
    contestId,
    isValidGift,
    initialGiftValues,

    // Form
    formValues,
    handleFormChange,
    formState,
    hasError,
  } = props

  const [openRemove, setOpenRemove] = useState(false)

  const confirmRemove = useCallback(async () => {
    await handleRemove()
    setOpenRemove(false)
  }, [handleRemove, setOpenRemove])

  const handleGiftSubmit = useCallback(
    giftValues => {
      handleFormChange({
        target: {
          name: "gifts",
          value: [...formValues.gifts, giftValues],
        },
      })
    },
    [handleFormChange, formValues]
  )

  const handleGiftRemove = useCallback(
    gift => {
      handleFormChange({
        target: {
          name: "gifts",
          value: formValues.gifts.filter(g => g != gift),
        },
      })
    },
    [handleFormChange, formValues]
  )

  return (
    <form autoComplete="off" noValidate>
      <CardHeader
        subheader="Créer ou modifier jeu concours"
        title={
          contestId ? "Détails : " + formValues.name : "Nouveau jeu concours"
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {formValues.password && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mot de passe"
                margin="dense"
                name="password"
                value={formValues.password}
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("name")}
              helperText={hasError("name") ? formState.errors["name"][0] : null}
              label="Nom jeu concours"
              margin="dense"
              name="name"
              onChange={handleFormChange}
              required
              value={formValues.name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("email_body")}
              helperText={
                hasError("email_body")
                  ? formState.errors["email_body"][0]
                  : null
              }
              label="Text pour les gagnants emails"
              margin="dense"
              name="email_body"
              onChange={handleFormChange}
              placeholder="Vous avez gagné ..."
              value={formValues.email_body}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("sms_body")}
              helperText={
                hasError("sms_body") ? formState.errors["sms_body"][0] : null
              }
              label="Text pour les gagnants sms"
              margin="dense"
              name="sms_body"
              onChange={handleFormChange}
              placeholder="Vous avez gagné ..."
              value={formValues.sms_body}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("cgu_body")}
              helperText={
                hasError("cgu_body") ? formState.errors["cgu_body"][0] : null
              }
              label="CGU à ajouter"
              margin="dense"
              name="cgu_body"
              onChange={handleFormChange}
              placeholder="Vous avez gagné ..."
              value={formValues.cgu_body}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("app_body")}
              helperText={
                hasError("app_body") ? formState.errors["app_body"][0] : null
              }
              label="Text pour l'app"
              margin="dense"
              name="app_body"
              onChange={handleFormChange}
              placeholder="Vérifié si vous avez gagné ..."
              value={formValues.app_body}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardHeader
        title="Liste des cadeaux"
        subheader="Créer ou modifier les cadeaux du jeu concours"
      />
      <GiftForm
        gifts={formValues.gifts}
        {...{
          handleSubmit: handleGiftSubmit,
          handleRemove: handleGiftRemove,
          initialValues: initialGiftValues,
          isValid: isValidGift,
        }}
      />
      <Divider />
      <CardActions>
        {contestId && (
          <Button color="primary" onClick={() => setOpenRemove(true)}>
            Archiver
          </Button>
        )}
        <div style={{ marginLeft: "auto" }}>
          {contestId && (
            <Button onClick={() => handleCancel()} color="primary">
              {"Annuler"}
            </Button>
          )}
          <Button
            style={{ marginLeft: "20px" }}
            color="primary"
            onClick={() => handleSubmit(formValues)}
          >
            {contestId ? "Mettre à jour" : "Créer jeu concours"}
          </Button>
        </div>
      </CardActions>
      <Dialog
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Voulez vous archiver ce jeu concours ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point d'archiver jeu concours "{contestId}".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemove(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmRemove} color="primary" autoFocus>
            Archiver
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default withForm(ContestForm)
