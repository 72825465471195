import React, { useEffect, useState } from "react"
import { withContests } from "@src/containers/contest"
import { Select } from "@material-ui/core"

const ContestSelect = props => {
  const {
    getContests,
    none = "Aucun jeu concours séléctionné",
    selectProps = {},
  } = props

  const [items, setItems] = useState([])

  useEffect(() => {
    getContests().then(events => setItems(events))
  }, [getContests, setItems])

  return (
    <Select {...selectProps} native>
      <option value="">{none}</option>
      {items.map(item => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </Select>
  )
}

export default withContests(ContestSelect)
