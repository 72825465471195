import React, { useState, useEffect, useCallback, useContext } from "react"
import { withContests } from "@src/containers/contest"
import { withRouter } from "@src/containers/router"
import { LoadingContext } from "@src/containers/loading"
import ContestForm from "@src/components/contests/ContestForm"
// const useStyles = makeStyles(theme => ({}))

const ContestDetail = props => {
  // const classes = useStyles()
  const {
    saveContest,
    getContestById,
    navigateContest,
    archiveContestById,
    navigateContestList,
    isValidContest,
    isValidGift,
    initialContestValues,
    initialGiftValues,
  } = props

  const { startLoading, stopLoading } = useContext(LoadingContext)
  const [initialValues, setInitialValues] = useState(initialContestValues)
  const [contestId, setContestId] = useState()

  const reset = useCallback(async () => {
    const newContestId = (props.location.state || {}).contestId || null

    if (newContestId) {
      const contest = await getContestById(newContestId)

      setInitialValues({
        ...initialContestValues,
        ...contest,
      })
    } else {
      setInitialValues(initialContestValues)
    }

    setContestId(newContestId)
  }, [setContestId, setInitialValues, getContestById, props.location.state])

  const handleSubmit = useCallback(
    async values => {
      try {
        startLoading()
        const contestSaved = await saveContest(values)
        await navigateContest(contestSaved.id)
        stopLoading()
      } catch (e) {
        console.log(e)
        stopLoading()
      }
    },
    [saveContest, navigateContest, startLoading, stopLoading]
  )

  const handleRemove = useCallback(async () => {
    try {
      startLoading()
      const isRemoved = await archiveContestById(contestId)
      if (isRemoved) navigateContestList()
      stopLoading()
    } catch (e) {
      console.log(e)
      stopLoading()
    }
  }, [
    contestId,
    navigateContestList,
    archiveContestById,
    startLoading,
    stopLoading,
  ])

  const handleCancel = useCallback(async () => {
    try {
      startLoading()
      await reset()
      stopLoading()
    } catch (e) {
      console.log(e)
      stopLoading()
    }
  }, [reset, startLoading, stopLoading])

  useEffect(() => {
    reset()
  }, [reset])

  return (
    <ContestForm
      {...{
        contestId,
        handleSubmit,
        handleRemove,
        handleCancel,
        initialValues,
        isValid: isValidContest,
        isValidGift,
        initialGiftValues,
      }}
    />
  )
}

export default withRouter(withContests(ContestDetail))
