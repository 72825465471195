import React, { useCallback, useContext } from "react"
import { FirebaseContext } from "gatsby-plugin-firebase"
import validate from "validate.js"
import shortid from "shortid"

const contestSchema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
}

const giftSchema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  total: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
    },
  },
  modeValue: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100,
    },
  },
}

const initialContestValues = {
  name: "",
  email_body: "",
  sms_body: "",
  cgu_body: "",
  app_body: "",
  archived: false,
  gifts: [],
  winners: [],
}

const initialGiftValues = {
  name: "",
  total: "",
  modeValue: "",
  count: 0,
  mode: "percent",
}

const withContests = Component => props => {
  const firebase = useContext(FirebaseContext)

  const getContestData = useCallback(
    async doc => {
      const { id, ref } = doc
      const contest = {
        id,
        gifts: [],
        winners: [],
        events: [],
        ...doc.data(),
      }

      const [
        giftSnapshot,
        winnerSnapshot,
        eventSnapshot,
        passwordSnapshot,
      ] = await Promise.all([
        firebase
          .firestore()
          .collection(`contests/${id}/gifts`)
          .get(),
        firebase
          .firestore()
          .collection(`contests/${id}/winners`)
          .get(),
        firebase
          .firestore()
          .collection(`events`)
          .where("contest", "==", ref)
          .get(),
        firebase
          .firestore()
          .collection(`contests/${id}/passwords`)
          .get(),
      ])

      giftSnapshot.forEach(gift => {
        contest.gifts.push({
          id: gift.id,
          ...gift.data(),
        })
      })

      winnerSnapshot.forEach(winner => {
        contest.winners.push({
          id: winner.id,
          ...winner.data(),
        })
      })

      eventSnapshot.forEach(event => {
        contest.events.push({
          id: event.id,
          name: event.data().name,
        })
      })

      passwordSnapshot.forEach(password => {
        contest.password = password.data().value
      })

      return contest
    },
    [firebase]
  )

  const getContests = useCallback(
    async (startAt = 0, limit = 10, orderBy = "name") => {
      const contests = []
      const refs = []

      const querySnapshot = await firebase
        .firestore()
        .collection("contests")
        .orderBy(orderBy)
        .startAt(startAt)
        .limit(limit)
        .get()

      querySnapshot.forEach(doc => {
        const data = doc.data()

        if (!data.archived || data.archived == false) {
          refs.push(doc)
        }
      })

      await Promise.all(
        refs.map(async doc => {
          const contest = await getContestData(doc)
          contests.push(contest)
        })
      )

      return contests
    },
    [firebase, getContestData]
  )

  const getContestById = useCallback(
    async id => {
      const doc = await firebase
        .firestore()
        .collection("contests")
        .doc(id)
        .get()

      return doc.exists ? getContestData(doc) : null
    },
    [firebase, getContestData]
  )

  const saveContest = useCallback(
    async (contest = {}) => {
      const { id, gifts, winners, events, ...data } = contest

      const collection = await firebase.firestore().collection("contests")

      const contestRef = id ? collection.doc(id) : collection.doc()

      const contestData = {
        ...data,
        updatedAt: firebase.firestore.Timestamp.now(),
        archived: false,
      }

      // Gifts
      const giftCollection = firebase
        .firestore()
        .collection(`contests/${contestRef.id}/gifts`)

      // Tokens
      const passwordCollection = firebase
        .firestore()
        .collection(`contests/${contestRef.id}/passwords`)

      if (!id) {
        contestData.createdAt = firebase.firestore.Timestamp.now()
        passwordCollection.doc().set({
          value: shortid.generate(contestRef.id),
        })
      }

      // Remove all previous gifts
      if (id) {
        const removedGift = []
        const giftSnapshot = await giftCollection.get()
        giftSnapshot.forEach(g => removedGift.push(g.id))
        await Promise.all(
          removedGift.map(giftId => giftCollection.doc(giftId).delete())
        )
      }

      // Save
      await Promise.all([
        contestRef.set(contestData),
        ...gifts.map(g =>
          giftCollection.doc().set({
            name: g.name,
            total: parseInt(g.total),
            modeValue: parseInt(g.modeValue),
            count: parseInt(g.count),
            mode: g.mode,
          })
        ),
      ])

      return {
        ...contestData,
        gifts,
        winners,
        events,
        id: contestRef.id,
      }
    },
    [firebase]
  )

  const archiveContestById = useCallback(
    async contestId => {
      if (contestId) {
        const collection = await firebase.firestore().collection("contests")
        const contest = collection.doc(contestId)
        const snapshot = await contest.get()

        if (snapshot.exists) {
          await contest.update({
            archived: true,
          })

          return true
        }
      }

      return false
    },
    [firebase]
  )

  const isValidContest = useCallback(values => {
    return validate(values, contestSchema)
  }, [])

  const isValidGift = useCallback(values => {
    return validate(values, giftSchema)
  }, [])

  return (
    <Component
      {...{
        getContests,
        saveContest,
        getContestById,
        initialContestValues,
        initialGiftValues,
        isValidContest,
        isValidGift,
        archiveContestById,
      }}
      {...props}
    />
  )
}

export default withContests
