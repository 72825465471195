import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Divider, Drawer } from "@material-ui/core"
import DashboardIcon from "@material-ui/icons/Dashboard"
import DevicesIcon from "@material-ui/icons/DeviceUnknown"
import EventsIcon from "@material-ui/icons/Assignment"
import ContestsIcon from "@material-ui/icons/LocalActivity"

import { Profile, SidebarNav, UpgradePlan } from "./components"

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  const pages = [
    {
      title: "Accueil",
      href: "/",
      icon: <DashboardIcon />,
    },
    {
      title: "Bornes",
      href: "/devices/",
      icon: <DevicesIcon />,
    },
    {
      title: "Evènements",
      href: "/events/",
      icon: <EventsIcon />,
    },
    {
      title: "Evènements Archivés",
      href: "/archives/",
      icon: <EventsIcon />,
    },
    /*{
      title: "Jeu concours",
      href: "/contests/",
      icon: <ContestsIcon />,
    },
    {
      title: "Account",
      href: "/account",
      icon: <AccountBoxIcon />,
    },
    {
      title: "Users",
      href: "/users/",
      icon: <PeopleIcon />,
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <SettingsIcon />,
    },*/
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {/*<Profile />
        <Divider className={classes.divider} />*/}
        <SidebarNav className={classes.nav} pages={pages} />
        {/*<UpgradePlan />*/}
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
