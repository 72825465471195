import React, { useCallback, useContext, useEffect, useState } from "react"
import { Drawer, Grid, Card, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import CloseIcon from "@material-ui/icons/Close"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { withDevices } from "@src/containers/device"
import { withRouter } from "@src/containers/router"
import { LoadingContext } from "@src/containers/loading"
import { DeviceToolbar, DeviceTable } from "./components"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

const DeviceList = props => {
  const classes = useStyles()
  const {
    getDevices,
    navigateEvent,
    navigateDeviceList,
    navigateDevice,
    navigateNewDevice,
  } = props
  const { startLoading, stopLoading } = useContext(LoadingContext)

  const [isOpen, setIsOpen] = useState(false)
  const [devices, setDevices] = useState([])

  const updateDeviceList = useCallback(
    async (startAt, limit, orderBy) => {
      try {
        startLoading()
        setDevices(await getDevices(startAt, limit, orderBy))
        stopLoading()
      } catch (e) {
        console.log(e)
        stopLoading()
      }
    },
    [getDevices, setDevices]
  )

  const handleShowDevice = useCallback(d => navigateDevice(d.id), [])

  const handleShowEvent = useCallback(d => navigateEvent(d.eventId), [])

  const handleCreateDevice = useCallback(() => navigateNewDevice(), [])

  const handleCloseDevice = useCallback(() => navigateDeviceList(), [])

  useEffect(() => {
    setIsOpen(!!props["*"])
    updateDeviceList()
  }, [props["*"]])

  return (
    <div className={classes.root}>
      <DeviceToolbar handleCreateDevice={handleCreateDevice} />
      <Grid container spacing={4} className={classes.content}>
        <Grid item lg={7} xs={12}>
          <DeviceTable
            devices={devices}
            updateDeviceList={updateDeviceList}
            handleShowDevice={handleShowDevice}
            handleShowEvent={handleShowEvent}
          />
        </Grid>
        {isWidthUp("lg", props.width) ? (
          <Grid item lg={5} xs={12}>
            <Card>{props.children}</Card>
          </Grid>
        ) : (
          <Drawer
            anchor="right"
            PaperProps={{
              style: {
                width: "90%",
                maxWidth: 600,
              },
            }}
            open={isOpen}
            onClose={handleCloseDevice}
            children={props.children}
          >
            <Grid container>
              <Grid item xs={12} container justify="flex-end">
                <IconButton aria-label="delete" onClick={handleCloseDevice}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                {props.children}
              </Grid>
            </Grid>
          </Drawer>
        )}
      </Grid>
    </div>
  )
}

export default withRouter(withDevices(withWidth()(DeviceList)))
