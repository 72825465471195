import React, { useCallback, useContext, useEffect, useState } from "react"
import { FirebaseContext } from "gatsby-plugin-firebase"
import { makeStyles } from "@material-ui/core/styles"
import Loading from "@src/components/Loading"
import { LoadingContext, withLoading } from "@src/containers/loading"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CardContent,
  Card,
  CardHeader,
  TextField,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"

const useStyles = makeStyles({
  table: {
    maxWidth: 600,
    width: "100%",
  },
})

const ContestValidation = props => {
  const firebase = useContext(FirebaseContext)
  const [contestId] = useState(props["*"])
  const [winnerList, setWinnerList] = useState([])
  const [title, setTitle] = useState("")
  const [password, setPassword] = useState("")
  const { loading, startLoading, stopLoading } = useContext(LoadingContext)

  const classes = useStyles()

  const updateList = useCallback(async () => {
    if (!firebase || !contestId) return

    const dbContestWinnerList = firebase
      .functions()
      .httpsCallable("dbContestsGetWinnerList")

    startLoading()
    const list = await dbContestWinnerList({ contestId, password })
    stopLoading()

    setWinnerList(list.data.winners || [])
    setTitle(list.data.title || "")
  }, [firebase, setWinnerList, contestId, password])

  const updateValidation = useCallback(
    async winnerId => {
      if (!firebase || !contestId) return

      const dbContestWinnerValidate = firebase
        .functions()
        .httpsCallable("dbContestsValidateWinner")

      startLoading()
      await dbContestWinnerValidate({ winnerId, contestId, password })
      await updateList()
      stopLoading()
    },
    [firebase, updateList, contestId, startLoading, stopLoading, password]
  )

  useEffect(() => {
    updateList()
  }, [updateList])

  return (
    <Card className={classes.table} style={{ margin: "15px auto" }}>
      <Loading loading={loading} />
      <CardHeader title={`Jeu concours : ${title}`} />
      <CardContent>
        <TextField
          fullWidth
          label="Mot de passe"
          margin="dense"
          name="name"
          value={password}
          variant="outlined"
          onChange={e => setPassword(e.target.value)}
        />
      </CardContent>
      <CardContent style={{ padding: 0 }}>
        <PerfectScrollbar>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell align="right">Validation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {winnerList.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.code}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      disabled={row.validate}
                      href="#text-buttons"
                      color="primary"
                      onClick={() => updateValidation(row.id)}
                    >
                      {row.validate ? "Terminer" : "Valider"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

export default withLoading(ContestValidation)
