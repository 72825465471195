import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react"
import { withEvents } from "@src/containers/event"
import { withRouter } from "@src/containers/router"
import {
  Divider,
  Grid,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormHelperText,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { LoadingContext } from "@src/containers/loading"
import { SaveAlt, Folder } from "@material-ui/icons"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import ContestSelect from "../../components/contests/ContestSelect"

const useStyles = makeStyles(theme => ({
  preview: {
    position: "relative",
    width: "320px",
    overflow: "hidden",
    marginRight: theme.spacing(4),
  },
  previewImg: {
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
    transform: "translate3d(-50%, -50%, 0)",
  },
  previewSizer: {
    width: "100%",
    paddingBottom: "133.333333%",
  },
  previewBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    border: "2px solid red",
    zIndex: 2,
  },
  action: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

const EventDetail = props => {
  const classes = useStyles()
  const {
    saveEvent,
    getEventById,
    initialEventValues,
    isValidEvent,
    navigateEvent,
    exportEvent,
    archiveEventById,
    unarchiveEventById,
    deleteEventById,
    navigateEventList,
    navigateArchiveList,
    archived,
  } = props
  const { startLoading, stopLoading } = useContext(LoadingContext)

  const stickerRef = useRef()

  const [eventId, setEventId] = useState()
  const [exportUrl, setExportUrl] = useState()
  const [exportZip, setExportZip] = useState()
  const [exporting, setExporting] = useState(false)
  const [values, setValues] = useState(initialEventValues)
  const [stickers, setStickers] = useState({})
  const [openRemove, setOpenRemove] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [formState, setFormState] = useState({
    isValid: false,
    touched: {},
    errors: {},
  })

  const handleChange = useCallback(
    e => {
      if (e.persist) e.persist()

      setValues(prevValues => ({
        ...prevValues,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      }))

      setFormState(formState => ({
        ...formState,
        touched: {
          ...formState.touched,
          [e.target.name]: true,
        },
      }))
    },
    [setValues, setFormState]
  )

  const handleStickerChange = useCallback(
    e => {
      e.persist()

      const input = e.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = er =>
          handleChange({
            target: { name: input.name, value: er.target.result },
          })

        reader.readAsDataURL(input.files[0])

        setStickers(prevSticker => ({
          ...prevSticker,
          [input.name]: input.files[0],
        }))
      }
    },
    [handleChange, setStickers]
  )

  const handleSubmit = useCallback(async () => {
    try {
      startLoading()
      const eventSaved = await saveEvent(values, stickers)
      await navigateEvent(eventSaved.id)
      stopLoading()
    } catch (e) {
      console.log(e)
      stopLoading()
    }
  }, [saveEvent, values, stickers, navigateEvent])

  const handleExport = useCallback(async () => {
    try {
      setExporting(true)

      const [report, archive] = await Promise.all([exportEvent(eventId)])
      setExportUrl(report.downloadURL)
      setExportZip(report.zipURL)
      setExporting(false)
    } catch (e) {
      console.log(e)
      setExporting(false)
    }
  }, [saveEvent, values, stickers, navigateEvent])

  const handleRemove = useCallback(async () => {
    try {
      startLoading()
      const isRemoved = (await archived)
        ? unarchiveEventById(eventId)
        : archiveEventById(eventId)
      if (isRemoved) {
        archived ? navigateArchiveList() : navigateEventList()
      }
      setOpenRemove(false)
      stopLoading()
    } catch (e) {
      console.log(e)
      stopLoading()
    }
  }, [
    values,
    eventId,
    navigateEventList,
    navigateArchiveList,
    setOpenRemove,
    archiveEventById,
    unarchiveEventById,
  ])

  const handleDelete = useCallback(async () => {
    try {
      startLoading()
      await deleteEventById(eventId)
      navigateArchiveList()
      setOpenDelete(false)
      stopLoading()
    } catch (e) {
      console.log(e)
      stopLoading()
    }
  }, [eventId, navigateArchiveList, setOpenDelete, deleteEventById])

  const hasError = useCallback(
    field => formState.touched[field] && formState.errors[field],
    [formState.touched, formState.errors]
  )

  useEffect(() => {
    const errors = isValidEvent(values)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [values])

  useEffect(() => {
    const newEventId = (props.location.state || {}).eventId || null

    if (newEventId) {
      getEventById(newEventId).then(event =>
        setValues({
          ...initialEventValues,
          ...event,
        })
      )
    } else {
      setValues(initialEventValues)
    }

    setEventId((props.location.state || {}).eventId || null)
  }, [setEventId, setValues, getEventById, props.location.state])

  return !archived || eventId ? (
    <form autoComplete="off" noValidate>
      <CardHeader
        subheader="Créer ou modifier l'évènement"
        title={eventId ? "Détails : " + values.name : "Nouvel évènement"}
        action={
          <React.Fragment>
            <Button
              className={classes.action}
              color="primary"
              variant="contained"
              disabled={!eventId || exporting}
              onClick={() => handleExport()}
            >
              {exporting ? <CircularProgress size={24} /> : "Nouvel export"}
            </Button>
            <Button
              className={classes.action}
              variant="contained"
              color="primary"
              disabled={exporting || !exportUrl}
              download={exportUrl}
              href={exportUrl}
            >
              <SaveAlt />
            </Button>
            <Button
              className={classes.action}
              variant="contained"
              color="primary"
              disabled={exporting || !exportZip}
              download={exportZip}
              href={exportZip}
            >
              <Folder />
            </Button>
          </React.Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={9} xs={12}>
            <TextField
              fullWidth
              error={hasError("name")}
              helperText={hasError("name") ? formState.errors["name"][0] : null}
              label="Nom de l'évènement"
              margin="dense"
              name="name"
              onChange={handleChange}
              required
              value={values.name}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              fullWidth
              error={hasError("timer")}
              helperText={
                hasError("timer") ? formState.errors["timer"][0] : null
              }
              label="Minuteur"
              margin="dense"
              name="timer"
              onChange={handleChange}
              type="number"
              value={values.timer}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Sec</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.email_enabled}
                  onChange={handleChange}
                  name="email_enabled"
                  color="primary"
                />
              }
              label="Emails ?"
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.sms_enabled}
                  onChange={handleChange}
                  name="sms_enabled"
                  color="primary"
                />
              }
              label="Téléphones ?"
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.cgu_enabled}
                  onChange={handleChange}
                  color="primary"
                  name="cgu_enabled"
                />
              }
              label="CGU ?"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("email_subject")}
              helperText={
                hasError("email_subject")
                  ? formState.errors["email_subject"][0]
                  : null
              }
              label="Sujet de l'email"
              margin="dense"
              name="email_subject"
              onChange={handleChange}
              required
              placeholder="Votre photo candela ..."
              value={values.email_subject}
              disabled={!values.email_enabled}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("email_body")}
              helperText={
                hasError("email_body")
                  ? formState.errors["email_body"][0]
                  : null
              }
              label="Contenu de l'email"
              placeholder="Retrouver votre photo candela ..."
              margin="dense"
              name="email_body"
              onChange={handleChange}
              required
              multiline
              rowsMax="6"
              value={values.email_body}
              disabled={!values.email_enabled}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("sms_body")}
              helperText={
                hasError("sms_body") ? formState.errors["sms_body"][0] : null
              }
              label="Contenu du sms"
              placeholder="Votre photo candela ..."
              margin="dense"
              name="sms_body"
              onChange={handleChange}
              required
              value={values.sms_body}
              disabled={!values.sms_enabled}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("cgu_body")}
              helperText={
                hasError("cgu_body") ? formState.errors["cgu_body"][0] : null
              }
              label="Explication du lien CGU"
              margin="dense"
              name="cgu_body"
              placeholder="En cochant cette case ..."
              onChange={handleChange}
              required
              value={values.cgu_body}
              disabled={!values.cgu_enabled}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel
                htmlFor="contestId-select"
                shrink={true}
                variant="outlined"
              >
                Associer un jeu concours
              </InputLabel>
              <ContestSelect
                selectProps={{
                  value: values.contestId,
                  onChange: handleChange,
                  inputProps: { id: "contestId-select", name: "contestId" },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} container direction="row" wrap="nowrap">
            {values.sticker ? (
              <figure className={classes.preview}>
                <img
                  className={classes.previewImg}
                  src={values.sticker}
                  alt=""
                />
                <div className={classes.previewSizer} />
                <div className={classes.previewBorder} />
              </figure>
            ) : null}
            <div>
              <input
                ref={stickerRef}
                accept="image/*"
                style={{ display: "none" }}
                id="sticker-button-file"
                multiple
                type="file"
                name="sticker"
                onChange={handleStickerChange}
              />
              <label
                htmlFor="sticker-button-file"
                style={{ marginRight: "20px", display: "inline-block" }}
              >
                <Button variant="contained" color="primary" component="span">
                  Choisir un sticker
                </Button>
              </label>
              {values.sticker && (
                <label
                  style={{
                    marginRight: "20px",
                    marginTop: "20px",
                    display: "inline-block",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => {
                      stickerRef.current.value = ""
                      if (stickers["sticker"]) {
                        delete stickers["sticker"]
                        setStickers(stickers)
                      }
                      handleChange({
                        target: { name: "sticker", value: "" },
                      })
                    }}
                  >
                    Supprimer le sticker
                  </Button>
                </label>
              )}
              <FormHelperText>
                Dimensions idéales : 3/4, 1668px de large par 2224px de haut
              </FormHelperText>
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        {eventId && (
          <Button color="primary" onClick={() => setOpenRemove(true)}>
            {archived ? "Desarchiver l'évènement" : "Archiver l'évènement"}
          </Button>
        )}
        {!archived && (
          <Button
            color="primary"
            style={{ marginLeft: "auto" }}
            onClick={handleSubmit}
          >
            {eventId ? "Mettre à jour" : "Créer l'évènement"}
          </Button>
        )}
        {eventId && archived && (
          <Button
            color="primary"
            style={{ marginLeft: "auto" }}
            onClick={() => setOpenDelete(true)}
          >
            Supprimer l'évènement
          </Button>
        )}
      </CardActions>
      <Dialog
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Voulez vous {archived ? "désarchiver" : "archiver"} cette évènement ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point {archived ? "de désarchiver" : "d'archiver"}{" "}
            l'évènement "{eventId}".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemove(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={() => handleRemove()} color="primary" autoFocus>
            {archived ? "Desarchiver" : "Archiver"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Voulez vous supprimer cette évènement ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de supprimer l'évènement "{eventId}".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={() => handleDelete()} color="primary" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  ) : (
    <span></span>
  )
}

export default withRouter(withEvents(EventDetail))
