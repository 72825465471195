import React, { useEffect, useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import { makeStyles } from "@material-ui/styles"
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link,
} from "@material-ui/core"
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Launch as IconLink,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  tableRow: {
    cursor: "pointer",
  },
}))

const Checked = ({ checked }) =>
  checked ? <CheckBox /> : <CheckBoxOutlineBlank />

const ContestTable = props => {
  const {
    className,
    contests,
    handleUpdateContestList,
    handleShowContest,
    ...rest
  } = props

  const classes = useStyles()

  const [selectedContest] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handlePageChange = (e, page) => {
    setPage(page)
  }

  const handleRowsPerPageChange = e => {
    setRowsPerPage(e.target.value)
  }

  useEffect(() => {
    handleUpdateContestList(page * rowsPerPage, rowsPerPage)
  }, [page, rowsPerPage])

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell align="center">Evènements</TableCell>
                  <TableCell align="center">Validés / Gagnants</TableCell>
                  <TableCell align="center">Distribués / Cadeaux</TableCell>
                  <TableCell align="center">Lien validation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contests.slice(0, rowsPerPage).map(contest => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={contest.id}
                    selected={selectedContest.indexOf(contest.id) !== -1}
                    onClick={() => handleShowContest(contest)}
                  >
                    <TableCell>{contest.name}</TableCell>
                    <TableCell align="center">
                      {contest.events.length}
                    </TableCell>
                    <TableCell align="center">
                      {contest.winners.reduce(
                        (s, w) => (s + w.validate ? 1 : 0),
                        0
                      )}
                      /{contest.winners.length}
                    </TableCell>
                    <TableCell align="center">
                      {contest.gifts.reduce((s, g) => s + g.count, 0)}/
                      {contest.gifts.reduce((s, g) => s + g.total, 0)}
                    </TableCell>
                    <TableCell align="center">
                      <Link
                        href={`/validation/${contest.id}`}
                        target="_blank"
                        onClick={e => e.stopPropagation()}
                      >
                        <IconLink />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={contests.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}

ContestTable.propTypes = {
  className: PropTypes.string,
  contests: PropTypes.array.isRequired,
}

export default ContestTable
