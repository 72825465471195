import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/styles"
import { useMediaQuery } from "@material-ui/core"
import { Sidebar, Topbar, Footer } from "./components"
import Loading from "@src/components/Loading"
import { LoadingContext, withLoading } from "@src/containers/loading"
import { withRouter } from "@src/containers/router"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: "100vh",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: "100%",
    position: "relative",
    overflowY: "scroll",
  },
}))

const Main = props => {
  const { children, navigateSignout } = props

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  const { loading } = useContext(LoadingContext)

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} onSignout={navigateSignout} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <main className={classes.content}>
        <Loading loading={loading} />
        {children}
        <Footer />
      </main>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default withLoading(withRouter(Main))
