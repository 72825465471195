import React from "react"
import { Router } from "@reach/router"
import { ThemeProvider, makeStyles } from "@material-ui/styles"
import { withAuthentication, withAuthorization } from "@src/containers/auth"
import theme from "@src/theme"
import { Main as Layout } from "@src/layouts"
import Signin from "@src/views/SignIn"
// import Signup from "@src/views/SignUp"
// import Account from "@src/views/Account"
import { EventList, EventDetail } from "@src/views/Events"
import { DeviceList, DeviceDetail } from "@src/views/Devices"
import {
  ContestList,
  ContestDetail,
  ContestValidation,
} from "@src/views/Contests"
import Dashboard from "@src/views/Dashboard"

const useStyles = makeStyles({
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
    },
    html: {
      height: "100%",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    body: {
      backgroundColor: "#f4f6f8",
      height: "100%",
    },
  },
})

const PrivateRoute = withAuthorization(
  "/signin/",
  ({ component: Component, ...rest }) => <Component {...rest} />
)

const App = props => {
  useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Signin path="signin" />
        <ContestValidation path="/validation/*" />
        {/*<Signup path="signup" />*/}
        <PrivateRoute path="/" component={Layout}>
          <Dashboard path="/" />
          {/*<Account path="account" />*/}
          <DeviceList path="devices">
            <DeviceDetail path="/*" />
          </DeviceList>
          <EventList key="events" path="events">
            <EventDetail path="/*" />
          </EventList>
          <EventList key="archives" path="archives" archived={true}>
            <EventDetail path="/*" archived={true} />
          </EventList>
          <ContestList path="contests">
            <ContestDetail path="/*" />
          </ContestList>
        </PrivateRoute>
      </Router>
    </ThemeProvider>
  )
}

export default withAuthentication(App)
