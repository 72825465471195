import React, { useCallback, useState } from "react"
import { useFirebase } from "gatsby-plugin-firebase"

const AuthUserContext = React.createContext(null)

const withAuthentication = Component => props => {
  const [user, setUser] = useState()
  const [isReady, setIsReady] = useState(false)

  const saveUser = useCallback(
    authUser => {
      setUser(authUser)
      setIsReady(true)
    },
    [setUser, setIsReady]
  )

  useFirebase(firebase => {
    firebase.auth().onAuthStateChanged(saveUser)
  }, [])

  return (
    <AuthUserContext.Provider value={user}>
      {isReady ? <Component {...props} /> : null}
    </AuthUserContext.Provider>
  )
}

export default withAuthentication
export { AuthUserContext }
