import React, { useCallback, useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Drawer, Grid, Card, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { withEvents } from "@src/containers/event"
import { withRouter } from "@src/containers/router"
import { EventToolbar, EventTable } from "./components"
import { LoadingContext } from "@src/containers/loading"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

const EventList = props => {
  const classes = useStyles()
  const {
    getEvents,
    getEventsCount,
    getArchivedEvents,
    getArchivedEventsCount,
    navigateEvent,
    navigateEventList,
    navigateArchive,
    navigateNewEvent,
    archived,
  } = props

  const { startLoading, stopLoading } = useContext(LoadingContext)

  const [isOpen, setIsOpen] = useState(false)
  const [events, setEvents] = useState([])
  const [eventsCount, setEventsCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)

  const handleUpdateEventList = useCallback(
    async (p, rpp, orderBy) => {
      try {
        startLoading()
        setPage(p)
        setRowsPerPage(rpp)
        if (archived) {
          setEvents(await getArchivedEvents(p * rpp, rpp, orderBy))
          setEventsCount(await getArchivedEventsCount())
        } else {
          setEvents(await getEvents(p * rpp, rpp, orderBy))
          setEventsCount(await getEventsCount())
        }
        stopLoading()
      } catch (e) {
        console.log(e)
        stopLoading()
      }
    },
    [
      getEvents,
      setEvents,
      setEventsCount,
      setRowsPerPage,
      setPage,
      getArchivedEvents,
      getArchivedEventsCount,
      archived,
    ]
  )

  const handleShowEvent = useCallback(
    event => (archived ? navigateArchive(event.id) : navigateEvent(event.id)),
    [navigateArchive, navigateEvent]
  )

  const handleCreateEvent = useCallback(() => navigateNewEvent(), [
    navigateNewEvent,
  ])

  const handleCloseEvent = useCallback(() => navigateEventList(), [
    navigateEventList,
  ])

  useEffect(() => {
    setIsOpen(!!props["*"])
    handleUpdateEventList(page, rowsPerPage)
  }, [props["*"], rowsPerPage, page])

  return (
    <div className={classes.root}>
      <EventToolbar handleCreateEvent={handleCreateEvent} />
      <Grid container spacing={4} className={classes.content}>
        <Grid item lg={7} xs={12}>
          <EventTable
            events={events}
            count={eventsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            handleUpdateEventList={handleUpdateEventList}
            handleShowEvent={handleShowEvent}
          />
        </Grid>
        {isWidthUp("lg", props.width) ? (
          <Grid item lg={5} xs={12}>
            <Card>{props.children}</Card>
          </Grid>
        ) : (
          <Drawer
            anchor="right"
            PaperProps={{
              style: {
                width: "90%",
                maxWidth: 600,
              },
            }}
            open={isOpen}
            onClose={handleCloseEvent}
            children={props.children}
          >
            <Grid container>
              <Grid item xs={12} container justify="flex-end">
                <IconButton aria-label="delete" onClick={handleCloseEvent}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                {props.children}
              </Grid>
            </Grid>
          </Drawer>
        )}
      </Grid>
    </div>
  )
}

export default withRouter(withEvents(withWidth()(EventList)))
