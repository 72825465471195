import React, { useCallback, useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Drawer, Grid, Card, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { withContests } from "@src/containers/contest"
import { withRouter } from "@src/containers/router"
import { ContestToolbar, ContestTable } from "./components"
import { LoadingContext } from "@src/containers/loading"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

const ContestList = props => {
  const classes = useStyles()
  const {
    getContests,
    navigateContest,
    navigateContestList,
    navigateNewContest,
  } = props

  const { startLoading, stopLoading } = useContext(LoadingContext)

  const [isOpen, setIsOpen] = useState(false)
  const [contests, setContests] = useState([])

  const handleUpdateContestList = useCallback(
    async (startAt, limit, orderBy) => {
      try {
        startLoading()
        setContests(await getContests(startAt, limit, orderBy))
        stopLoading()
      } catch (e) {
        console.log(e)
        stopLoading()
      }
    },
    [getContests, setContests]
  )

  const handleShowContest = useCallback(
    contest => navigateContest(contest.id),
    [navigateContest]
  )

  const handleCreateContest = useCallback(() => navigateNewContest(), [
    navigateNewContest,
  ])

  const handleCloseContest = useCallback(() => navigateContestList(), [
    navigateContestList,
  ])

  useEffect(() => {
    setIsOpen(!!props["*"])
    handleUpdateContestList()
  }, [props["*"]])

  return (
    <div className={classes.root}>
      <ContestToolbar handleCreateContest={handleCreateContest} />
      <Grid container spacing={4} className={classes.content}>
        <Grid item lg={7} xs={12}>
          <ContestTable
            contests={contests}
            handleUpdateContestList={handleUpdateContestList}
            handleShowContest={handleShowContest}
          />
        </Grid>
        {isWidthUp("lg", props.width) ? (
          <Grid item lg={5} xs={12}>
            <Card>{props.children}</Card>
          </Grid>
        ) : (
          <Drawer
            anchor="right"
            PaperProps={{
              style: {
                width: "90%",
                maxWidth: 600,
              },
            }}
            open={isOpen}
            onClose={handleCloseContest}
            children={props.children}
          >
            <Grid container>
              <Grid item xs={12} container justify="flex-end">
                <IconButton aria-label="delete" onClick={handleCloseContest}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                {props.children}
              </Grid>
            </Grid>
          </Drawer>
        )}
      </Grid>
    </div>
  )
}

export default withRouter(withContests(withWidth()(ContestList)))
