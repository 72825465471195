import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby-link"
import { AuthUserContext } from "./withAuthentication"

const withAuthorization = (redirect, Component) => props => {
  const user = useContext(AuthUserContext)
  const [isAuthorize, setIsAuthorize] = useState(false)
  const [role] = useState(props.role)

  useEffect(() => {
    if (user) {
      // Testing roles, else
      setIsAuthorize(true)
      // else await navigate("/app/")
    } else navigate(redirect)
  }, [user, setIsAuthorize])

  return isAuthorize ? <Component {...props} /> : null
}

export default withAuthorization
