import React, { useCallback } from "react"
import { navigate } from "@reach/router"

const paths = {
  events: "/events/",
  devices: "/devices/",
  archives: "/archives/",
  // contests: "/contests/",
}

const withRouter = Component => props => {
  // Events
  const navigateEventList = useCallback(() => navigate(paths.events), [])

  const navigateEvent = useCallback(
    eventId => navigate(paths.events + eventId, { state: { eventId } }),
    []
  )

  const navigateArchiveList = useCallback(() => navigate(paths.archives), [])

  const navigateArchive = useCallback(
    eventId => navigate(paths.archives + eventId, { state: { eventId } }),
    []
  )

  const navigateNewEvent = useCallback(() => navigate(paths.events + "new"), [])

  // Devices
  const navigateDeviceList = useCallback(() => navigate(paths.devices), [])

  const navigateDevice = useCallback(
    deviceId => navigate(paths.devices + deviceId, { state: { deviceId } }),
    []
  )

  const navigateNewDevice = useCallback(
    () => navigate(paths.devices + "new"),
    []
  )

  const navigateSignout = useCallback(
    () => navigate("/signin/", { state: { signout: true } }),
    []
  )

  const navigateContestList = useCallback(() => navigate(paths.contests), [])

  const navigateContest = useCallback(
    contestId => navigate(paths.contests + contestId, { state: { contestId } }),
    []
  )

  const navigateNewContest = useCallback(
    () => navigate(paths.contests + "new"),
    []
  )

  return (
    <Component
      {...{
        navigateEventList,
        navigateEvent,
        navigateArchiveList,
        navigateArchive,
        navigateNewEvent,
      }}
      {...{ navigateDeviceList, navigateDevice, navigateNewDevice }}
      {...{ navigateContestList, navigateContest, navigateNewContest }}
      {...{ navigateSignout }}
      {...props}
    />
  )
}

export default withRouter
