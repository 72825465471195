import React, { useCallback, useEffect, useState } from "react"

const initialFormState = {
  isValid: false,
  touched: {},
  errors: {},
}

const withForm = Component => props => {
  const { isValid, initialValues } = props
  const [values, setValues] = useState(initialValues)
  const [formState, setFormState] = useState(initialFormState)

  useEffect(() => {
    const errors = isValid(values)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [values])

  const handleChange = useCallback(
    e => {
      if (e.persist) e.persist()
      console.log(e.target.value)
      setValues(prevValues => ({
        ...prevValues,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      }))

      setFormState(formState => ({
        ...formState,
        touched: {
          ...formState.touched,
          [e.target.name]: true,
        },
      }))
    },
    [setValues, setFormState]
  )

  const hasError = useCallback(
    field => !!(formState.touched[field] && formState.errors[field]),
    [formState.touched, formState.errors]
  )

  const resetForm = useCallback(() => {
    setValues(initialValues)
    setFormState(initialFormState)
  }, [setValues, initialValues])

  useEffect(() => {
    resetForm()
  }, [initialValues])

  return (
    <Component
      {...props}
      handleFormChange={handleChange}
      formValues={values}
      setFormValues={values => setValues(values)}
      formState={formState}
      hasError={hasError}
      resetForm={resetForm}
    />
  )
}

export default withForm
