import React, { useEffect, useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import { makeStyles } from "@material-ui/styles"
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link,
} from "@material-ui/core"
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Launch as StickerLink,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  tableRow: {
    cursor: "pointer",
  },
}))

const Checked = ({ checked }) =>
  checked ? <CheckBox /> : <CheckBoxOutlineBlank />

const EventTable = props => {
  const {
    className,
    events,
    count,
    handleUpdateEventList,
    handleShowEvent,
    rowsPerPage,
    page,
    ...rest
  } = props

  const classes = useStyles()

  const [selectedEvent, setSelectedEvent] = useState([])

  const handleSelectAll = e => {
    const { events } = props

    let selectedEvent

    if (e.target.checked) {
      selectedEvent = events.map(event => event.id)
    } else {
      selectedEvent = []
    }

    setSelectedEvent(selectedEvent)
  }

  const handleSelectOne = (e, id) => {
    const selectedIndex = selectedEvent.indexOf(id)
    let newSelectedEvent = []

    if (selectedIndex === -1) {
      newSelectedEvent = newSelectedEvent.concat(selectedEvent, id)
    } else if (selectedIndex === 0) {
      newSelectedEvent = newSelectedEvent.concat(selectedEvent.slice(1))
    } else if (selectedIndex === selectedEvent.length - 1) {
      newSelectedEvent = newSelectedEvent.concat(selectedEvent.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedEvent = newSelectedEvent.concat(
        selectedEvent.slice(0, selectedIndex),
        selectedEvent.slice(selectedIndex + 1)
      )
    }

    setSelectedEvent(newSelectedEvent)
  }

  const handlePageChange = (e, p) => {
    handleUpdateEventList(p, rowsPerPage)
  }

  const handleRowsPerPageChange = e => {
    handleUpdateEventList(page, e.target.value)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedEvent.length === events.length}
                      color="primary"
                      indeterminate={
                        selectedEvent.length > 0 &&
                        selectedEvent.length < events.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>*/}
                  <TableCell>Nom</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Téléphone</TableCell>
                  <TableCell align="center">Sticker</TableCell>
                  {/*<TableCell>Borne</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {events.slice(0, rowsPerPage).map(event => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={event.id}
                    selected={selectedEvent.indexOf(event.id) !== -1}
                    onClick={() => handleShowEvent(event)}
                  >
                    {/*<TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedEvent.indexOf(event.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, event.id)}
                        value="true"
                      />
                    </TableCell>*/}
                    <TableCell>{event.name}</TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      style={{ width: 100 }}
                    >
                      <Checked checked={event.email_enabled} />
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      style={{ width: 100 }}
                    >
                      <Checked checked={event.sms_enabled} />
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      style={{ width: 100 }}
                    >
                      <Link
                        href={event.sticker}
                        target="_blank"
                        onClick={e => e.stopPropagation()}
                      >
                        <StickerLink />
                      </Link>
                    </TableCell>
                    {/*<TableCell>
                      <Link href={"#"} target="_blank">
                        Device
                      </Link>
                    </TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={count}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50]}
        />
      </CardActions>
    </Card>
  )
}

EventTable.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array.isRequired,
}

export default EventTable
