import React from "react"
import { CircularProgress, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    background: "rgba(255, 255, 255, 0.5)",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.loading,
  },
}))

const Loading = ({ loading }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      style={{ display: loading ? "" : "none" }}
      justify="center"
      alignContent="center"
      className={classes.root}
    >
      <CircularProgress />
    </Grid>
  )
}

export default Loading
