import React, { useCallback } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/AddCircle"
import withForm from "@src/containers/form/withForm"

const GiftForm = props => {
  const { handleFormChange, formValues, formState, hasError, resetForm } = props
  const { gifts, handleSubmit, handleRemove } = props

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell align="center">Distribué</TableCell>
          <TableCell align="center">Total</TableCell>
          <TableCell align="center">% Gagnant</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>
            <TextField
              fullWidth
              required
              placeholder="Nom"
              size="small"
              name="name"
              error={hasError("name")}
              helperText={hasError("name") ? formState.errors["name"][0] : null}
              value={formValues.name}
              onChange={handleFormChange}
            />
          </TableCell>
          <TableCell align="center" width="100px">
            <TextField
              required
              size="small"
              type="number"
              inputProps={{ min: "0" }}
              name="total"
              error={hasError("total")}
              helperText={
                hasError("total") ? formState.errors["total"][0] : null
              }
              value={formValues.total}
              onChange={handleFormChange}
            />
          </TableCell>
          <TableCell align="center" width="100px">
            <TextField
              required
              size="small"
              type="number"
              inputProps={{ min: "0" }}
              name="modeValue"
              error={hasError("modeValue")}
              helperText={
                hasError("modeValue") ? formState.errors["modeValue"][0] : null
              }
              value={formValues.modeValue}
              onChange={handleFormChange}
            />
          </TableCell>
          <TableCell padding="checkbox" align="center">
            <IconButton
              disabled={!formState.isValid}
              size="small"
              edge="end"
              aria-label="add"
              onClick={() => {
                handleSubmit(formValues)
                resetForm()
              }}
            >
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        {gifts.map((g, i) => (
          <TableRow key={i}>
            <TableCell>{g.name}</TableCell>
            <TableCell align="center">{g.count}</TableCell>
            <TableCell align="center">{g.total}</TableCell>
            <TableCell align="center">{`${g.modeValue}%`}</TableCell>
            <TableCell padding="checkbox" align="center">
              <IconButton
                size="small"
                edge="end"
                aria-label="delete"
                onClick={() => handleRemove(g)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default withForm(GiftForm)
