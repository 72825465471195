import React, { useCallback, useState } from "react"

const LoadingContext = React.createContext({
  loading: false,
  startLoading: () => {},
  stopLoading: () => {},
})

const withLoading = Component => props => {
  const [loading, setLoading] = useState(false)
  const startLoading = useCallback(() => setLoading(true), [setLoading])
  const stopLoading = useCallback(() => setLoading(false), [setLoading])

  return (
    <LoadingContext.Provider
      value={{
        loading,
        startLoading,
        stopLoading,
      }}
    >
      <Component {...props} />
    </LoadingContext.Provider>
  )
}

export default withLoading
export { LoadingContext }
