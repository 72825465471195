import React, { useEffect, useState } from "react"
import { withEvents } from "@src/containers/event"
import { Select } from "@material-ui/core"

const EventSelect = props => {
  const {
    getEvents,
    none = "Aucun évènement séléctionné",
    selectProps = {},
  } = props

  const [items, setItems] = useState([])

  useEffect(() => {
    getEvents(0, 25, "updatedAt").then(events => setItems(events))
  }, [getEvents, setItems])

  return (
    <Select {...selectProps} native>
      <option value="">{none}</option>
      {items.map(item => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </Select>
  )
}

export default withEvents(EventSelect)
