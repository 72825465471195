import React, { useEffect, useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import { makeStyles } from "@material-ui/styles"
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Link,
} from "@material-ui/core"
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Launch as StickerLink,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  tableRow: {
    cursor: "pointer",
  },
}))

const Checked = ({ checked }) =>
  checked ? <CheckBox /> : <CheckBoxOutlineBlank />

const DeviceTable = props => {
  const {
    className,
    devices,
    updateDeviceList,
    handleShowDevice,
    handleShowEvent,
    ...rest
  } = props

  const classes = useStyles()

  const [selectedDevice, setSelectedDevice] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleSelectAll = e => {
    const { devices } = props

    let selectedDevice

    if (e.target.checked) {
      selectedDevice = devices.map(device => device.id)
    } else {
      selectedDevice = []
    }

    setSelectedDevice(selectedDevice)
  }

  const handleSelectOne = (e, id) => {
    const selectedIndex = selectedDevice.indexOf(id)
    let newSelectedDevice = []

    if (selectedIndex === -1) {
      newSelectedDevice = newSelectedDevice.concat(selectedDevice, id)
    } else if (selectedIndex === 0) {
      newSelectedDevice = newSelectedDevice.concat(selectedDevice.slice(1))
    } else if (selectedIndex === selectedDevice.length - 1) {
      newSelectedDevice = newSelectedDevice.concat(selectedDevice.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedDevice = newSelectedDevice.concat(
        selectedDevice.slice(0, selectedIndex),
        selectedDevice.slice(selectedIndex + 1)
      )
    }

    setSelectedDevice(newSelectedDevice)
  }

  const handlePageChange = (e, page) => {
    setPage(page)
  }

  const handleRowsPerPageChange = e => {
    setRowsPerPage(e.target.value)
  }

  useEffect(() => {
    updateDeviceList(page * rowsPerPage, rowsPerPage)
  }, [page, rowsPerPage])

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedDevice.length === devices.length}
                      color="primary"
                      indeterminate={
                        selectedDevice.length > 0 &&
                        selectedDevice.length < devices.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>*/}
                  <TableCell>Nom</TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell align="center">Actif</TableCell>
                  <TableCell align="center">Event</TableCell>
                  {/*<TableCell>Borne</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.slice(0, rowsPerPage).map(device => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={device.id}
                    selected={selectedDevice.indexOf(device.id) !== -1}
                    onClick={() => handleShowDevice(device)}
                  >
                    {/*<TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedDevice.indexOf(device.id) !== -1}
                        color="primary"
                        onChange={device => handleSelectOne(device, device.id)}
                        value="true"
                      />
                    </TableCell>*/}
                    <TableCell>{device.name}</TableCell>
                    <TableCell>{device.id}</TableCell>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      style={{ width: 100 }}
                    >
                      <Checked checked={device.active} />
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Link
                        onClick={e => {
                          e.stopPropagation()
                          handleShowEvent(device)
                        }}
                      >
                        <StickerLink />
                      </Link>
                    </TableCell>
                    {/*<TableCell>
                      <Link href={"#"} target="_blank">
                        Device
                      </Link>
                    </TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={devices.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}

DeviceTable.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.array.isRequired,
}

export default DeviceTable
