import React, { useCallback, useContext, useEffect, useState } from "react"
import { useFirebase } from "gatsby-plugin-firebase"
import { navigate } from "@reach/router"
import { AuthUserContext } from "@src/containers/auth"

const withSignIn = Component => props => {
  const user = useContext(AuthUserContext)
  const [signinError, setSigninError] = useState({})
  const [credentials, setCredentials] = useState({})
  const handleRegister = useCallback(() => navigate("//signup/"), [])
  const [isSignout] = useState(props.location.state.signout)

  const handleForgotPassword = useCallback(
    () => navigate("/forgot-password/"),
    []
  )

  const handleSubmit = useCallback(
    newCredentials => {
      // Type connexion
      setCredentials(newCredentials)
    },
    [setCredentials]
  )

  const goAdmin = useCallback(async () => {
    await navigate("/", { replace: true })
  }, [])

  useEffect(() => {
    if (!isSignout && user) goAdmin()
  }, [user, isSignout])

  useFirebase(
    firebase => {
      const auth = firebase.auth()

      if (credentials.email && credentials.password) {
        const { email, password, rememberme } = credentials
        const persistence =
          firebase.auth.Auth.Persistence[rememberme ? "LOCAL" : "SESSION"]

        setSigninError({})

        auth
          .setPersistence(persistence)
          .then(() => auth.signInWithEmailAndPassword(email, password))
          .then(() => goAdmin())
          .catch(e => setSigninError(e))
      } else if (isSignout) {
        auth.signOut()
      }
    },
    [credentials, goAdmin]
  )

  return (
    <Component
      signinError={signinError}
      handleRegister={handleRegister}
      handleForgotPassword={handleForgotPassword}
      handleSubmit={handleSubmit}
      {...props}
    />
  )
}

export default withSignIn
