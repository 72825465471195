import React, { useCallback, useContext } from "react"
import { FirebaseContext } from "gatsby-plugin-firebase"
import validate from "validate.js"

const eventSchema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
}

const initialEventValues = {
  name: "",
  timer: 3,
  sticker: "",
  stickerRef: "",
  email_enabled: true,
  sms_enabled: true,
  email_subject: "",
  email_body: "",
  sms_body: "",
  cgu_enabled: true,
  cgu_body: "",
  archived: false,
  contest: false,
}

const withEvents = Component => props => {
  const firebase = useContext(FirebaseContext)

  const getReportByEventId = firebase
    .functions()
    .httpsCallable("dbEventsReportsGetByEventId")

  const getEventsCount = useCallback(async () => {
    const querySnapshot = await firebase
      .firestore()
      .collection("events")
      .where("archived", "==", false)
      .get()

    return querySnapshot.size
  }, [firebase])

  const getEvents = useCallback(
    async (startAt = 0, limit = 25, orderBy = "name") => {
      const events = []

      const querySnapshot = await firebase
        .firestore()
        .collection("events")
        .where("archived", "==", false)
        .orderBy(orderBy)
        .limit(startAt + limit)
        .get()

      let index = 0

      querySnapshot.forEach(doc => {
        if (index >= startAt && index < startAt + limit) {
          const data = doc.data()
          if (!data.deleted) {
            events.push({
              id: doc.id,
              ...data,
            })
          }
        }
        index++
      })

      return events
    },
    [firebase]
  )

  const getEventById = useCallback(
    async id => {
      const doc = await firebase
        .firestore()
        .collection("events")
        .doc(id)
        .get()

      return {
        ...doc.data(),
        id: doc.id,
      }
    },
    [firebase]
  )

  const saveEvent = useCallback(
    async (event = {}, files = {}) => {
      const { id, contestId, dateCreate, ...data } = event

      const collection = await firebase.firestore().collection("events")

      const eventRef = id ? collection.doc(id) : collection.doc()

      const EventStorageRef = await firebase
        .storage()
        .ref()
        .child("events")
        .child(eventRef.id)
        .child("stickers")

      const uploads = {}

      await Promise.all(
        Object.keys(files).map(async name => {
          const uploadRef = await EventStorageRef.child(name)
          await uploadRef.put(files[name])
          uploads[name] = await uploadRef.getDownloadURL()
          uploads[name + "Ref"] = uploadRef.fullPath
        })
      )

      const eventData = {
        ...data,
        ...uploads,
        updatedAt: firebase.firestore.Timestamp.now(),
        archived: false,
      }

      if (!id) {
        eventData.createdAt = firebase.firestore.Timestamp.now()
      }

      if (contestId) {
        const contestCollection = await firebase
          .firestore()
          .collection("contests")
        const contest = await contestCollection.doc(contestId).get()
        if (contest.exists) eventData.contest = contest.ref
      }

      await eventRef.set(eventData)

      return {
        ...eventData,
        id: eventRef.id,
      }
    },
    [firebase]
  )

  const getArchivedEventsCount = useCallback(async () => {
    const querySnapshot = await firebase
      .firestore()
      .collection("events")
      .where("archived", "==", true)
      .get()

    return querySnapshot.size
  }, [firebase])

  const getArchivedEvents = useCallback(
    async (startAt = 0, limit = 25, orderBy = "name") => {
      const events = []

      const querySnapshot = await firebase
        .firestore()
        .collection("events")
        .where("archived", "==", true)
        .orderBy(orderBy)
        .limit(startAt + limit)
        .get()

      let index = 0

      querySnapshot.forEach(doc => {
        if (index >= startAt && index < startAt + limit) {
          const data = doc.data()
          if (!data.deleted) {
            events.push({
              id: doc.id,
              ...data,
            })
          }
        }
        index++
      })

      return events
    },
    [firebase]
  )

  const archiveEventById = useCallback(
    async eventId => {
      if (eventId) {
        const collection = await firebase.firestore().collection("events")
        const event = collection.doc(eventId)
        const snapshot = await event.get()

        if (snapshot.exists) {
          await event.update({
            archived: true,
          })

          return true
        }
      }

      return false
    },
    [firebase]
  )

  const unarchiveEventById = useCallback(
    async eventId => {
      if (eventId) {
        const collection = await firebase.firestore().collection("events")
        const event = collection.doc(eventId)
        const snapshot = await event.get()

        if (snapshot.exists) {
          await event.update({
            archived: false,
          })

          return true
        }
      }

      return false
    },
    [firebase]
  )

  const isValidEvent = useCallback(values => {
    return validate(values, eventSchema)
  }, [])

  const exportEvent = useCallback(
    eventId =>
      new Promise(async (resolve, reject) => {
        const db = firebase.firestore()
        const { data } = await getReportByEventId({ eventId })
        const reportRef = await db.doc(data.path)
        const unsubscribe = reportRef.onSnapshot(doc => {
          const report = doc.data()
          if (report.status === "complete") unsubscribe(), resolve(report)
          else if (report.status === "error") unsubscribe(), reject(report)
        })
      }),
    []
  )

  const deleteEventById = useCallback(
    async eventId => {
      if (eventId) {
        const collection = await firebase.firestore().collection("events")
        const event = collection.doc(eventId)
        const snapshot = await event.get()

        if (snapshot.exists) {
          await event.update({
            deleted: true,
          })

          return true
        }
      }

      return false
    },
    [firebase]
  )

  return (
    <Component
      {...{
        saveEvent,
        getEvents,
        getEventById,
        getEventsCount,
        getArchivedEvents,
        archiveEventById,
        unarchiveEventById,
        getArchivedEventsCount,
        initialEventValues,
        isValidEvent,
        exportEvent,
        deleteEventById,
      }}
      {...props}
    />
  )
}

export default withEvents
