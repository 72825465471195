import React, { useState, useEffect, useCallback, useContext } from "react"
import {
  Divider,
  Grid,
  CardHeader,
  CardContent,
  TextField,
  CardActions,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core"
import { withDevices } from "@src/containers/device"
import { withRouter } from "@src/containers/router"
import { EventSelect } from "@src/components/events"
import { LoadingContext } from "@src/containers/loading"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"

const DeviceDetail = props => {
  const {
    saveDevice,
    getDeviceById,
    archiveDeviceById,
    initialDeviceValues,
    isValidDevice,
    navigateDevice,
    navigateDeviceList,
  } = props
  const { startLoading, stopLoading } = useContext(LoadingContext)

  const [deviceId, setDeviceId] = useState()
  const [values, setValues] = useState(initialDeviceValues)
  const [event, setEvent] = useState()
  const [openRemove, setOpenRemove] = useState(false)
  const [formState, setFormState] = useState({
    isValid: false,
    touched: {},
    errors: {},
  })

  const handleChange = useCallback(
    e => {
      if (e.persist) e.persist()

      setValues(prevValues => ({
        ...prevValues,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      }))

      setFormState(formState => ({
        ...formState,
        touched: {
          ...formState.touched,
          [e.target.name]: true,
        },
      }))
    },
    [setValues, setFormState]
  )

  const handleSubmit = useCallback(async () => {
    try {
      startLoading()
      const deviceSaved = await saveDevice(values, event)
      await navigateDevice(deviceSaved.id)
      stopLoading()
    } catch (e) {
      console.log(e)
      stopLoading()
    }
  }, [saveDevice, values, event, navigateDevice])

  const handleRemove = useCallback(async () => {
    try {
      startLoading()
      await archiveDeviceById(deviceId)
      navigateDeviceList()
      setOpenRemove(false)
      stopLoading()
    } catch (e) {
      console.log(e)
      stopLoading()
    }
  }, [values, deviceId, archiveDeviceById, navigateDeviceList, setOpenRemove])

  const hasError = useCallback(
    field => formState.touched[field] && formState.errors[field],
    [formState.touched, formState.errors]
  )

  useEffect(() => {
    const errors = isValidDevice(values)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [values])

  useEffect(() => {
    const newEventId = (props.location.state || {}).deviceId || null

    if (newEventId) {
      getDeviceById(newEventId).then(event =>
        setValues({
          ...initialDeviceValues,
          ...event,
        })
      )
    } else {
      setValues(initialDeviceValues)
    }

    setDeviceId((props.location.state || {}).deviceId || null)
  }, [setDeviceId, setValues, getDeviceById, props.location.state])

  return (
    <form autoComplete="off" noValidate>
      <CardHeader
        subheader="Créer ou modifier le device"
        title={deviceId ? "Détails : " + values.name : "Nouveau device"}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("id")}
              helperText={hasError("id") ? formState.errors["id"][0] : null}
              label="UUID du device"
              margin="dense"
              name="id"
              onChange={handleChange}
              required
              value={values.id}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={hasError("name")}
              helperText={hasError("name") ? formState.errors["name"][0] : null}
              label="Surnom de la borne"
              margin="dense"
              name="name"
              onChange={handleChange}
              required
              value={values.name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.active}
                  onChange={handleChange}
                  name="active"
                  color="primary"
                />
              }
              label="Activer la borne"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel
                htmlFor="eventId-select"
                shrink={true}
                variant="outlined"
              >
                Associer à un évènement
              </InputLabel>
              <EventSelect
                selectProps={{
                  value: values.eventId,
                  onChange: handleChange,
                  inputProps: { id: "eventId-select", name: "eventId" },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        {deviceId && (
          <Button color="primary" onClick={() => setOpenRemove(true)}>
            Supprimer le device
          </Button>
        )}
        <Button
          color="primary"
          style={{ marginLeft: "auto" }}
          onClick={handleSubmit}
        >
          {deviceId ? "Mettre à jour" : "Ajouter le device"}
        </Button>
      </CardActions>
      <Dialog
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Voulez vous supprimer ce device ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de supprimer le device "{deviceId}".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemove(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleRemove} color="primary" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default withRouter(withDevices(DeviceDetail))
