import React, { useCallback, useContext } from "react"
import { FirebaseContext } from "gatsby-plugin-firebase"
import validate from "validate.js"

const deviceSchema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
}

const initialDeviceValues = {
  id: "",
  name: "",
  active: false,
}

const withDevices = Component => props => {
  const firebase = useContext(FirebaseContext)

  const getDevices = useCallback(
    async (startAt = 0, limit = 10, orderBy = "name") => {
      const devices = []

      const querySnapshot = await firebase
        .firestore()
        .collection("devices")
        .orderBy(orderBy)
        .startAt(startAt)
        .limit(limit)
        .get()

      querySnapshot.forEach(doc => {
        const { event, ...data } = doc.data()

        devices.push({
          ...data,
          id: doc.id,
          eventId: event && event.id,
        })
      })

      return devices
    },
    [firebase]
  )

  const getDeviceById = useCallback(
    async id => {
      const doc = await firebase
        .firestore()
        .collection("devices")
        .doc(id)
        .get()

      const { event, ...data } = doc.data()

      return {
        ...data,
        id: doc.id,
        eventId: event && event.id,
      }
    },
    [firebase]
  )

  const saveDevice = useCallback(
    async (device = {}) => {
      const { id, eventId, ...data } = device

      const deviceData = data
      const collection = await firebase.firestore().collection("devices")
      const DeviceRef = id ? collection.doc(id) : collection.doc()

      if (eventId) {
        const eventCollection = await firebase.firestore().collection("events")
        const event = await eventCollection.doc(eventId).get()
        if (event.exists) deviceData.event = event.ref
      }

      await DeviceRef.set({
        ...deviceData,
      })

      return {
        ...data,
        id: DeviceRef.id,
      }
    },
    [firebase]
  )

  const archiveDeviceById = useCallback(
    async deviceId => {
      if (deviceId) {
        const collection = await firebase.firestore().collection("devices")
        return collection.doc(deviceId).delete()
      }
    },
    [firebase]
  )

  const isValidDevice = useCallback(values => {
    return validate(values, deviceSchema)
  }, [])

  return (
    <Component
      {...{
        getDevices,
        saveDevice,
        getDeviceById,
        isValidDevice,
        initialDeviceValues,
        archiveDeviceById,
      }}
      {...props}
    />
  )
}

export default withDevices
